var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", [
        _c("div", { staticClass: "mb-2" }, [
          _c(
            "h1",
            [
              _vm._v("\n      Where will (or did) you file your\n      "),
              _c("br"),
              _vm._v("\n      state formation paperwork for\n      "),
              _c("br"),
              _vm._v("\n      " + _vm._s(_vm.company.name) + "?\n      "),
              _c("tool-tips", {
                attrs: {
                  position: "right",
                  width: "15",
                  content: _vm.toolTipDesc,
                },
              }),
            ],
            1
          ),
          _c("hr"),
        ]),
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "div",
              { attrs: { id: "registration-form-div" } },
              [
                _c("company-domestic-registration-form", {
                  staticClass: "registration-drop-down",
                  attrs: { company: _vm.company, "load-products": false },
                  on: { changed: _vm.domesticRegistrationChanged },
                }),
              ],
              1
            ),
            _c(
              "b-button",
              {
                staticClass: "btn btn-primary submit-button mt-4",
                attrs: { "aria-label": "submit button", disabled: !_vm.valid },
                on: { click: _vm.submit },
              },
              [_vm._v("\n      Save\n    ")]
            ),
          ],
          1
        ),
      ])
    : _c("ct-centered-spinner", { staticClass: "mt-5" }, [
        _vm._v("\n  Saving...\n"),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }