<template>
  <div v-if="loaded">
    <div class="mb-2">
      <h1>
        Where will (or did) you file your
        <br>
        state formation paperwork for
        <br>
        {{ company.name }}?
        <tool-tips
          position="right"
          width="15"
          :content="toolTipDesc"
        />
      </h1>
      <hr>
    </div>

    <div class="col-md-12">
      <div id="registration-form-div">
        <company-domestic-registration-form
          class="registration-drop-down"
          :company="company"
          :load-products="false"
          @changed="domesticRegistrationChanged"
        />
      </div>

      <b-button
        class="btn btn-primary submit-button mt-4"
        aria-label="submit button"
        :disabled="!valid"
        @click="submit"
      >
        Save
      </b-button>
    </div>
  </div>
  <ct-centered-spinner v-else class="mt-5">
    Saving...
  </ct-centered-spinner>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: 'SelectDomesticRegistration',
  components: {
    CompanyDomesticRegistrationForm: () => import('@/components/HireUs/CompanyDomesticRegistrationForm'),
    CtCenteredSpinner:               () => import('@/components/shared/CtCenteredSpinner'),
    ToolTips:                        () => import('@/components/StagelineV2/toolTips/ToolTips'),
  },
  data() {
    return {
      loaded: true,
      toolTipDesc: '<p>The jurisdiction you choose is your home, or "domestic," state. ' +
        'Most business owners choose the state where they conduct business or hold assets.</p>',
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
    ]),
  },
  methods: {
    ...mapActions('companies', [
      'updateDomesticRegistrationJurisdiction',
    ]),
    valid() {
      return this.newHomeState != null
    },
    domesticRegistrationChanged(oldHomeState, newHomeState) {
      this.oldHomeState = oldHomeState
      this.newHomeState = newHomeState
    },
    async submit() {
      this.loaded = false
      if (this.newHomeState) {
        await this.updateDomesticRegistrationJurisdiction({
          companyId: this.company.id,
          jurisdictionId: this.newHomeState.id,
          loadProducts: false,
        })
      }
      this.$emit('next')
      this.loaded = true
    },
  },
}
</script>

<style lang="scss" scoped>
hr {
  width: 100%;
  padding-top: 0.6em;
  border-bottom: 2px solid #FFFFFF20;
  margin-top: 1.5em;
}
#registration-form-div {
  justify-content: center;
  display: flex;
}
.submit-button, .registration-drop-down {
  width: 18.5em;
  height: 3.125em;
}
</style>
